<template>
  <section>
    <b-modal
      hide-footer
      centered
      title="주문취소"
      :visible="visible"
      @hidden="$emit('hidden')"
    >
      <b-card-body>
        <b-card-text>
          <div>주문 취소 상품금액 : {{ cancelOrderAmount }}</div>
          <div>주문 취소 배송비 : {{ cancelOrderShippingFee }}</div>
          <div>취소 후 남은 주문 : {{ remainOrderNoList }}</div>
        </b-card-text>
      </b-card-body>
      <b-card-footer>
        <b-button
          class="mr-75"
          variant="primary"
          @click="$emit('cancelOrderPartial')"
        >주문 부분취소</b-button>
        <b-button
          variant="success"
          @click="$emit('cancelOrderGruop')"
        >장바구니 주문 전체취소</b-button>
      </b-card-footer>
    </b-modal>
  </section>
</template>

<script>
import {
  BModal,
  BCardText,
  BCardBody,
  BCardFooter,
  BButton,
} from 'bootstrap-vue'
import { ref, computed } from '@vue/composition-api'

export default {
  components: {
    BModal,
    BCardText,
    BCardBody,
    BCardFooter,
    BButton,
  },
  props: {
    cancelOrderNoList: {
      type: Array[String],
      default: [],
    },
    remainOrderNoList: {
      type: Array[String],
      default: [],
    },
    cancelOrderAmount: {
      type: Number,
      default: null,
    },
    cancelOrderShippingFee: {
      type: Number,
      default: null,
    },
  },
  setup(props) {
    const visible = computed(() => props.cancelOrderNoList.length > 0)
    return {
      visible,
    }
  },
}
</script>

<style>

</style>