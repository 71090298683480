import { ref, watch, computed } from '@vue/composition-api'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import VueRouter from 'vue-router'
import moment from 'moment-timezone'
import store from '@/store'
import router from '@/router'

export default function useInvoicesList() {
  // Use toast
  const toast = useToast()

  const refInvoiceListTable = ref(null)
  const cancelOrderGroupNo = ref(null)
  const cancelOrderNoList = ref([])
  const cancelOrderAmount = ref(null)
  const cancelOrderShippingFee = ref(null)
  const remainOrderList = ref([])

  const orderedCount = ref(0)
  const notDeliveryYetCount = ref(0)
  const deliveryCount = ref(0)
  const cancelingCount = ref(0)

  const searchParams = ref({
    page: 1,
    size: 20,
    total: 0,
    state: [],
    pay_method: [],
    order_no: [],
    myshop_name: [],
    product_name: [],
    vendor_idx: [],
    vendor_name: [],
    legacy_vendor_no: [],
    tracking_no: [],
    buyer_name: [],
    buyer_phone: [],
    buyer_email: [],
    recipient_name: [],
    recipient_phone: [],
    depositor_name: [],
    created_at: [],
    ...router.history.current.query,
  })

  const searchKeys = [
    'state', 'pay_method', 'order_no', 'myshop_name', 'product_name',
    'vendor_idx', 'vendor_name', 'legacy_vendor_no', 'tracking_no', 'buyer_name',
    'buyer_phone', 'buyer_email', 'recipient_name', 'recipient_phone', 'depositor_name',
    'created_at',
  ]

  const queryToVariableSync = () => {
    searchParams.value = {
      page: 1,
      size: 20,
      total: 0,
      state: [],
      pay_method: [],
      order_no: [],
      myshop_name: [],
      product_name: [],
      vendor_idx: [],
      vendor_name: [],
      legacy_vendor_no: [],
      tracking_no: [],
      buyer_name: [],
      buyer_phone: [],
      buyer_email: [],
      recipient_name: [],
      recipient_phone: [],
      depositor_name: [],
      created_at: [],
      ...router.history.current.query,
    }
    searchKeys.forEach(key => {
      if (searchParams.value[key] == null || searchParams.value[key] === '') {
        searchParams.value[key] = []
      } else if (typeof searchParams.value[key] === 'string') {
        searchParams.value[key] = [searchParams.value[key]]
      }
    })
  }

  queryToVariableSync()

  // Table Handlers
  const tableColumns = [
    { key: 'idx', label: 'idx' },
    { key: 'order_group_no', label: '주문번호' },
    { key: 'product_name', label: '상품명 (스토어)' },
    { key: 'depositor_name', label: '구매자' },
    { key: 'price', label: '상품 결제금액' },
    { key: 'shipping_info', label: '배송정보' },
    { key: 'state', label: '주문상태' },
    { key: 'created_at', label: '주문일시', formatter: val => `${moment(val).format('YYYY-MM-DD HH:mm:ss')}` },
  ]

  const invoices = ref([])
  const totalCount = ref(searchParams.value.total)
  const perPage = ref(searchParams.value.size)
  const currentPage = ref(searchParams.value.page)
  const isBusy = ref(false)

  const orderStateList = [
    { label: '결제대기', value: 'ORDERED' },
    { label: '결제완료', value: 'PAID' },
    { label: '배송준비중', value: 'READY' },
    { label: '배송중', value: 'DELIVERY' },
    { label: '배송완료', value: 'DONE' },
    { label: '취소중', value: 'CANCELING' },
    { label: '취소완료', value: 'CANCELED' },
    { label: '환불중', value: 'TAKEBACK' },
    { label: '환불완료', value: 'TAKEBACKED' },
    { label: '교환중', value: 'EXCHANGE' },
    { label: '교환완료', value: 'EXCHANGED' },
    { label: '결제이탈', value: 'REGISTER' },
  ]

  const dataMeta = computed(() => {
    const localItemsCount = refInvoiceListTable.value ? refInvoiceListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalCount.value,
    }
  })

  const downloadFile = (url, name) => {
    const tempLink = document.createElement('a')
    tempLink.style.display = 'none'
    tempLink.href = url
    if (name) {
      tempLink.setAttribute('download', name)
    } else {
      tempLink.setAttribute('download', '')
    }
    document.body.appendChild(tempLink)
    tempLink.click()
    document.body.removeChild(tempLink)
  }

  const resetOrderCancelModal = () => {
    cancelOrderGroupNo.value = null
    cancelOrderAmount.value = null
    cancelOrderShippingFee.value = null
    remainOrderList.value = []
    cancelOrderNoList.value = []
  }

  const datetimePreprocessing = datetime => datetime
    .map(o => o
      .split(' to ')
      .map(date => moment(new Date(date).setHours(0))
        .utc()
        .format('YYYY-MM-DD HH'))
      .join(' to '))

  const getOrderDashboard = () => {
    store
      .dispatch('app-invoice/getOrderDashboard')
      .then(response => {
        const { data } = response.data
        orderedCount.value = data.ordered_count
        notDeliveryYetCount.value = data.not_delivery_yet_count
        deliveryCount.value = data.delivery_count
        cancelingCount.value = data.canceling_count
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching order dashboard',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
      .finally(() => {
      })
  }

  const fetchOrderList = () => {
    isBusy.value = true
    store
      .dispatch('app-invoice/fetchOrderList', {
        ...searchParams.value,
        created_at: datetimePreprocessing(searchParams.value.created_at),
      })
      .then(response => {
        const { page_info, data } = response.data
        invoices.value = data
        totalCount.value = page_info.total_count
        searchParams.value.total = page_info.total_count
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching users list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
      .finally(() => {
        isBusy.value = false
        router.replace({
          query: searchParams.value,
        }).catch(() => { })

        getOrderDashboard()
      })
  }

  const willUpdateOrderState = ref(true)
  const inputWillUpdateOrderState = value => {
    willUpdateOrderState.value = value
  }

  const isDownloadingExcel = ref(false)
  const downOrderExcel = () => {
    isDownloadingExcel.value = true
    store
      .dispatch('app-invoice/sendOrderExcel', {
        ...searchParams.value,
        created_at: datetimePreprocessing(searchParams.value.created_at),
        will_update_order_state: willUpdateOrderState.value,
      })
      .then(res => {
        // const file = new File([res.data], 'order_info.xlsx', {
        //   type:
        //     'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        // })
        // const url = window.URL.createObjectURL(file)
        // downloadFile(url, 'order_info.xlsx')
        toast({
          component: ToastificationContent,
          props: {
            title: '엑셀 다운로드 요청에 성공했습니다',
            text: '이메일을 확인하세요',
            icon: 'AlertTriangleIcon',
            variant: 'primary',
          },
        })
      })
      .catch(error => {
        if (error.response.status === 400) {
          toast({
            component: ToastificationContent,
            props: {
              title: '다운로드에 실패했습니다',
              text: '결제완료 상태가 아닌 주문이 존재합니다',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        } else {
          toast({
            component: ToastificationContent,
            props: {
              title: '다운로드에 실패하였습니다',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      }).finally(() => {
        isDownloadingExcel.value = false
      })
  }

  const getCancelAmount = (orderGroupNo, orderNoList) => {
    store
      .dispatch('app-invoice/getCancelAmount', {
        order_group_no: orderGroupNo,
        order_no_list: orderNoList,
      })
      .then(response => {
        cancelOrderAmount.value = response.data.data.total_amount
        cancelOrderShippingFee.value = response.data.data.shipping_fee
        remainOrderList.value = response.data.data.remain_order
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: '주문 취소 조회에 실패하였습니다.',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const updateOrderState = (orderGroupNo, orderNoList, changeState) => {
    if (changeState === 'CANCELING' || changeState === 'CANCELED') {
      cancelOrderGroupNo.value = orderGroupNo
      cancelOrderNoList.value = orderNoList
      getCancelAmount(orderGroupNo, cancelOrderNoList.value)
      return
    }
    store
      .dispatch('app-invoice/updateOrderState', {
        order_group_no: orderGroupNo,
        order_no_list: orderNoList,
        change_state: changeState,
      })
      .then(() => {
        currentPage.value = 1
        fetchOrderList()
        toast({
          component: ToastificationContent,
          props: {
            title: '주문상태 업로드에 성공하였습니다.',
            icon: 'AlertTriangleIcon',
            variant: 'success',
          },
        })
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: '주문상태 업로드에 실패하였습니다.',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const cancelOrder = (orderGroupNo, orderNoList) => {
    store
      .dispatch('app-invoice/cancelOrder', {
        order_group_no: orderGroupNo,
        order_no_list: orderNoList,
      })
      .then(() => {
        currentPage.value = 1
        fetchOrderList()
        toast({
          component: ToastificationContent,
          props: {
            title: '주문 취소에 성공하였습니다.',
            icon: 'AlertTriangleIcon',
            variant: 'success',
          },
        })
        resetOrderCancelModal()
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: '주문 취소에 실패하였습니다.',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  watch([currentPage, perPage], () => {
    searchParams.value.page = currentPage.value
    searchParams.value.size = perPage.value
    fetchOrderList()
  })

  const resolveInvoiceStatusText = status => {
    if (status === 'ORDERED') return '결제대기'
    if (status === 'PAID') return '결제완료'
    if (status === 'READY') return '배송준비중'
    if (status === 'DELIVERY') return '배송중'
    if (status === 'DONE') return '배송완료'
    if (status === 'CANCELING') return '주문취소중'
    if (status === 'CANCELED') return '취소완료'
    if (status === 'TAKEBACK') return '환불중'
    if (status === 'TAKEBACKED') return '환불완료'
    if (status === 'EXCHANGE') return '교환중'
    if (status === 'EXCHANGED') return '교환완료'
    if (status === 'REGISTER') return '결제이탈'
    return '알 수 없음'
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*
  const rowClass = () => {
    const colClass = 'w-200'
    return colClass
  }

  return {
    fetchOrderList,
    getCancelAmount,
    cancelOrder,
    tableColumns,
    perPage,
    currentPage,
    totalCount,
    dataMeta,
    refInvoiceListTable,
    cancelOrderGroupNo,
    cancelOrderNoList,
    cancelOrderAmount,
    cancelOrderShippingFee,
    remainOrderList,
    searchParams,
    resolveInvoiceStatusText,
    downOrderExcel,
    updateOrderState,
    resetOrderCancelModal,
    rowClass,
    invoices,
    orderStateList,
    getOrderDashboard,
    orderedCount,
    notDeliveryYetCount,
    deliveryCount,
    cancelingCount,
    queryToVariableSync,
    isDownloadingExcel,
    willUpdateOrderState,
    inputWillUpdateOrderState,
    isBusy,
  }
}
