<template>

  <div>

    <OrderListFilters
      :search-params="searchParams"
      :order-count="orderedCount"
      :not-delivery-yet-count="notDeliveryYetCount"
      :delivery-count="deliveryCount"
      :canceling-count="cancelingCount"
      :isDownloadingExcel="isDownloadingExcel"
      :willUpdateOrderState="willUpdateOrderState"
      @inputWillUpdateOrderState="inputWillUpdateOrderState"
      @variable-sync="queryToVariableSync"
      @search="fetchOrderList"
      @download="downOrderExcel"
    />
    <!-- Table Container Card -->
    <b-card
      no-body
    >

      <OrderCancelModal
        :cancel-order-no-list="cancelOrderNoList"
        :cancel-order-amount="cancelOrderAmount"
        :cancel-order-shipping-fee="cancelOrderShippingFee"
        :remain-order-no-list="remainOrderList"
        @cancelOrderGruop="cancelOrder(cancelOrderGroupNo, [])"
        @cancelOrderPartial="cancelOrder(cancelOrderGroupNo, cancelOrderNoList)"
        @hidden="resetOrderCancelModal"
      />

      <b-table
        ref="refInvoiceListTable"
        :items="invoices"
        responsive
        :fields="tableColumns"
        primary-key="idx"
        show-empty
        empty-text="No matching records found"
        :tbody-tr-class="rowClass"
        class="position-relative"
        :busy="isBusy"
      >
        <template #table-busy>
          <div class="text-center">
            <b-spinner
              variant="primary"
              label="API Loading..."
            />
            <div>
              <strong>Loading...</strong>
            </div>
          </div>
        </template>

        <template #head(invoiceStatus)>
          <feather-icon
            icon="TrendingUpIcon"
            class="mx-auto"
          />
        </template>

        <!-- Column: Id -->
        <template #cell(idx)="data">
          <!-- <b-link
            :to="{ name: 'commerce-order-view', params: { idx: data.item.idx }}"
            class="font-weight-bold"
          >
            {{ data.item.idx }}
          </b-link> -->
          <b-link
            :to="{ name: 'commerce-order-group-detail', params: { idx: data.item.order_group_idx }}"
            class="font-weight-bold"
          >
            {{ data.item.idx }}
          </b-link>
        </template>

        <!-- Column: Id -->
        <template #cell(order_group_no)="data">
          <div class="font-small-3">
            {{ data.item.order_group.order_no }}
            {{ data.item.order_no }}
          </div>
        </template>

        <template #cell(product_name)="data">
          <div style="width:200px">
            <b-link
              :to="{ name: 'commerce-order-group-detail', params: { idx: data.item.order_group_idx }}"
            >
              {{ data.value }}
            </b-link>
            <div
              v-if="data.item.order_group.myshop"
            >
              ( {{ data.item.order_group.myshop.url_path }} )
            </div>
          </div>
        </template>

        <template #cell(shipping_info)="data">
          <b-col class="w-160">
            <div>
              {{ data.item.delivery.shipment_company }}
            </div>
            <div v-if="data.item.delivery.tracking_no">
              ( {{ data.item.delivery.tracking_no }} )
            </div>
          </b-col>
        </template>

        <template #cell(depositor_name)="data">
          <div
            v-if="data.item.order_group.buyer"
            class="100"
          >
            {{ data.item.order_group.buyer.name }}
          </div>
          <div
            v-if="data.item.order_group.buyer"
            class="w-100"
          >
            {{ data.item.order_group.buyer.phone }}
          </div>
        </template>

        <template #cell(price)="data">
          <div class="w-80">
            {{ (data.item.amount).toLocaleString() }}
          </div>
          <div class="w-80">
            ( {{ (data.item.order_group.total_pay).toLocaleString() }} )
          </div>
        </template>

        <template #cell(state)="data">
          <div style="width:50px">
            {{ resolveInvoiceStatusText(data.value) }}
          </div>
        </template>

        <template #cell(created_at)="data">
          <div class="w-120">
            {{ data.value }}
          </div>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalCount"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>

</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BLink,
  BPagination,
  BSpinner,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'
import useInvoicesList from './useInvoiceList'
import OrderListFilters from './components/OrderListFilters.vue'
import OrderCancelModal from './components/OrderCancelModal.vue'

import invoiceStoreModule from './orderStoreModule'

export default {
  components: {
    OrderListFilters,

    BCard,
    BRow,
    BCol,
    BTable,
    BLink,
    BPagination,
    BSpinner,

    vSelect,
    OrderCancelModal,
  },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-invoice'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, invoiceStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    const {
      fetchOrderList,
      getCancelAmount,
      cancelOrder,
      tableColumns,
      perPage,
      currentPage,
      totalCount,
      dataMeta,
      refInvoiceListTable,
      cancelOrderGroupNo,
      cancelOrderNoList,
      cancelOrderAmount,
      cancelOrderShippingFee,
      remainOrderList,
      searchParams,
      invoices,
      isBusy,
      resolveInvoiceStatusText,
      downOrderExcel,
      updateOrderState,
      resetOrderCancelModal,
      rowClass,
      orderStateList,
      getOrderDashboard,
      orderedCount,
      notDeliveryYetCount,
      deliveryCount,
      cancelingCount,
      queryToVariableSync,
      isDownloadingExcel,
      willUpdateOrderState,
      inputWillUpdateOrderState,
    } = useInvoicesList()

    fetchOrderList()

    return {
      fetchOrderList,
      getCancelAmount,
      cancelOrder,
      tableColumns,
      perPage,
      currentPage,
      totalCount,
      dataMeta,
      refInvoiceListTable,
      cancelOrderGroupNo,
      cancelOrderNoList,
      cancelOrderAmount,
      cancelOrderShippingFee,
      remainOrderList,
      searchParams,
      invoices,
      isBusy,
      avatarText,
      resolveInvoiceStatusText,
      downOrderExcel,
      rowClass,
      updateOrderState,
      resetOrderCancelModal,
      orderStateList,
      getOrderDashboard,
      orderedCount,
      notDeliveryYetCount,
      deliveryCount,
      cancelingCount,
      queryToVariableSync,
      isDownloadingExcel,
      willUpdateOrderState,
      inputWillUpdateOrderState,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}

$start: 1;
$end: 2000;
@for $i from $start through $end {
  .w-#{$i} {
    width: #{$i}px;
  }
}
</style>
