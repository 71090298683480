<template>
  <div>
    <b-card class="p-100">
      <b-card-header>
        <b-row class="w-100 mb-1">
          <b-col class="d-flex">
            <b-badge
              pill
              variant="primary"
              class="mr-1"
              :to="{name: 'commerce-order-list', query: {state: 'ORDERED', pay_method: 'ACCOUNT'}}"
              @click="reload"
            >
              입금확인중 : {{ orderCount }}
            </b-badge>
            <b-badge
              pill
              variant="warning"
              class="mr-1"
              :to="{name: 'commerce-order-list', query: {state: ['PAID', 'READY']}}"
              @click="reload"
            >
              배송준비중 : {{ notDeliveryYetCount }}
            </b-badge>
            <b-badge
              pill
              variant="info"
              class="mr-1"
              :to="{name: 'commerce-order-list', query: {state: 'DELIVERY'}}"
              @click="reload"
            >
              배송중 : {{ deliveryCount }}
            </b-badge>
            <b-badge
              pill
              variant="danger"
              class="mr-1"
              :to="{name: 'commerce-order-list', query: {state: 'CANCELING', pay_method: 'ACCOUNT'}}"
              @click="reload"
            >
              무통장취소중 : {{ cancelingCount }}
            </b-badge>
          </b-col>
        </b-row>
        <b-row class="w-100">
          <b-col>
            <h2 class="pb-50">
              검색 필터
            </h2>
          </b-col>
        </b-row>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col
            cols="12"
          >
            <b-form-group
              label="카테고리별 검색"
            >
              <b-row>
                <b-col
                  cols="12"
                  xl="2"
                >
                  <v-select
                    v-model="textCategorySelect"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="textCategoryOptions"
                    class="invoice-filter-select"
                    placeholder="검색 항목 선택"
                  >
                    <template #selected-option="{ label }">
                      <span class="text-truncate overflow-hidden">
                        {{ label }}
                      </span>
                    </template>
                  </v-select>
                </b-col>
                <b-col
                  v-if="isNotCheckbox"
                  cols="12"
                  xl="4"
                >
                  <b-input-group>
                    <flat-pickr
                      v-if="isDateInput"
                      v-model="textInput"
                      class="form-control"
                      :config="{ mode: 'range'}"
                    />

                    <b-form-input
                      v-else
                      v-model="textInput"
                      class="d-inline-block"
                      placeholder="검색 텍스트 입력"
                      @keyup.enter="addTag"
                    />
                    <b-input-group-append>
                      <b-button
                        variant="outline-primary"
                        @click="addTag"
                      >
                        Add
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-col>
                <b-col
                  v-else
                  cols="12"
                  xl="10"
                  class="d-flex align-items-center"
                >
                  <b-form-checkbox-group
                    v-if="isStateInput"
                    v-model="searchParams.state"
                    :options="stateOptions"
                    name="buttons-1"
                  />
                  <b-form-checkbox-group
                    v-else-if="isPayMethodInput"
                    v-model="searchParams.pay_method"
                    :options="payMethodOptions"
                    name="buttons-1"
                  />
                </b-col>
              </b-row>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-tags
              v-model="tagParams"
              input-id="tags-basic"
              class="mb-2"
              :tag-validator="() => false"
              invalid-tag-text="이곳에 직접 입력할 수 없습니다"
              placeholder="위에서 입력한 값을 보여줍니다"
            />
          </b-col>
        </b-row>

        <b-row>
          <b-col class="d-flex">
            <b-button
              variant="gradient-primary"
              class="mr-1"
              @click="search"
            >
              검색
            </b-button>
            <b-button
              v-b-modal.excel-modal
              variant="outline-success"
              class="mr-1"
              :disabled="isDownloadingExcel"
            >
              <b-spinner
                v-if="isDownloadingExcel"
                small
              />
              엑셀 다운로드
            </b-button>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <b-modal
      id="excel-modal"
      title="엑셀 다운로드"
      ok-title="확인"
      cancel-title="취소"
      cancel-variant="outline-secondary"
      centered
      @ok="download"
    >
      <b-form-checkbox
        :checked="willUpdateOrderState"
        @input="$emit('inputWillUpdateOrderState', $event)"
      >
        상품 준비중으로 상태 변경
      </b-form-checkbox>
    </b-modal>

  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BFormCheckboxGroup,
  BFormGroup,
  BFormInput,
  BButton,
  BFormTags,
  BInputGroup,
  BInputGroupAppend,
  VBToggle,
  BSidebar,
  BFormInvalidFeedback,
  BFormCheckbox,
  BBadge,
  BSpinner,
  BModal,
  VBModal,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment-timezone'
import store from '@/store'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BFormCheckboxGroup,
    BFormGroup,
    BFormInput,
    BButton,
    vSelect,
    BFormTags,
    BInputGroup,
    BInputGroupAppend,
    flatPickr,
    BBadge,
    BSpinner,
    BModal,
    BFormCheckbox,
  },
  directives: {
    'b-toggle': VBToggle,
    'b-modal': VBModal,
  },
  props: {
    searchParams: {
      type: Object,
      default: null,
    },
    orderCount: {
      type: Number,
      default: 0,
    },
    notDeliveryYetCount: {
      type: Number,
      default: 0,
    },
    deliveryCount: {
      type: Number,
      default: 0,
    },
    cancelingCount: {
      type: Number,
      default: 0,
    },
    isDownloadingExcel: {
      type: Boolean,
      default: false,
    },
    willUpdateOrderState: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      stateOptions: [
        { text: '결제대기', value: 'ORDERED' },
        { text: '결제완료', value: 'PAID' },
        { text: '배송준비중', value: 'READY' },
        { text: '배송중', value: 'DELIVERY' },
        { text: '배송완료', value: 'DONE' },
        { text: '취소중', value: 'CANCELING' },
        { text: '취소완료', value: 'CANCELED' },
        { text: '환불중', value: 'TAKEBACK' },
        { text: '환불완료', value: 'TAKEBACKED' },
        { text: '교환중', value: 'EXCHANGE' },
        { text: '교환완료', value: 'EXCHANGED' },
        { text: '결제이탈', value: 'REGISTER' },
      ],
      payMethodOptions: [
        { text: '무통장입금', value: 'ACCOUNT' },
        { text: '카드', value: 'CARD' },
        { text: '나이스페이', value: 'NICEPAY_AUTH' },
        { text: '네이버페이', value: 'NAVERPAY_AUTH' },
        { text: '포인트결제', value: 'POINT' },
      ],
      textCategoryOptions: [
        { label: '주문번호', value: 'ORDER_NO' },
        { label: '주문상태', value: 'STATE' },
        { label: '결제수단', value: 'PAY_METHOD' },
        { label: '스토어명', value: 'MYSHOP_NAME' },
        { label: '상품명', value: 'PRODUCT_NAME' },
        { label: '벤더번호', value: 'VENDOR_IDX' },
        { label: '벤더명', value: 'VENDOR_NAME' },
        { label: '벤더번호(1.0)', value: 'LEGACY_VENDOR_NO' },
        { label: '송장번호', value: 'TRACKING_NO' },
        { label: '구매자 이름', value: 'BUYER_NAME' },
        { label: '구매자 휴대번호', value: 'BUYER_PHONE' },
        { label: '구매자 이메일', value: 'BUYER_EMAIL' },
        { label: '수령자 이름', value: 'RECIPIENT_NAME' }, //
        { label: '수령자 휴대번호', value: 'RECIPIENT_PHONE' },
        { label: '입금자 이름', value: 'DEPOSITOR_NAME' },
        { label: '주문일시', value: 'CREATED_AT' },
      ],
      textCategorySelect: { label: '주문번호', value: 'ORDER_NO' },
      textInput: '',
    }
  },
  computed: {
    isDateInput: {
      get() {
        return this.textCategorySelect?.value === 'CREATED_AT'
      },
    },
    isStateInput: {
      get() {
        return this.textCategorySelect?.value === 'STATE'
      },
    },
    isPayMethodInput: {
      get() {
        return this.textCategorySelect?.value === 'PAY_METHOD'
      },
    },
    isNotCheckbox: {
      get() {
        return !this.isStateInput && !this.isPayMethodInput
      },
    },
    tagParams: {
      get() {
        const results = []
        const state = this.stateOptions.filter(o => this.searchParams.state.includes(o.value)).map(o => `주문상태:${o.text}`)
        const payMethod = this.payMethodOptions.filter(o => this.searchParams.pay_method.includes(o.value)).map(o => `결제수단:${o.text}`)
        const orderNo = this.searchParams.order_no.map(o => `주문번호:${o}`)
        const myshopName = this.searchParams.myshop_name.map(o => `스토어명:${o}`)
        const productName = this.searchParams.product_name.map(o => `상품명:${o}`)
        const vendorIdx = this.searchParams.vendor_idx.map(o => `벤더번호:${o}`)
        const vendorName = this.searchParams.vendor_name.map(o => `벤더명:${o}`)
        const legacyVendorNo = this.searchParams.legacy_vendor_no.map(o => `벤더번호(1.0):${o}`)
        const trackingNo = this.searchParams.tracking_no.map(o => `송장번호:${o}`)
        const buyerName = this.searchParams.buyer_name.map(o => `구매자_이름:${o}`)
        const buyerPhone = this.searchParams.buyer_phone.map(o => `구매자_휴대번호:${o}`)
        const buyerEmail = this.searchParams.buyer_email.map(o => `구매자_이메일:${o}`)
        const recipientName = this.searchParams.recipient_name.map(o => `수령자_이름:${o}`)
        const recipientPhone = this.searchParams.recipient_phone.map(o => `수령자_휴대번호:${o}`)
        const depositorName = this.searchParams.depositor_name.map(o => `입금자_이름:${o}`)
        const createdAt = this.searchParams.created_at.map(o => `주문일시:${o}`)
        return results
          .concat(state)
          .concat(payMethod)
          .concat(orderNo)
          .concat(myshopName)
          .concat(productName)
          .concat(vendorIdx)
          .concat(vendorName)
          .concat(legacyVendorNo)
          .concat(trackingNo)
          .concat(buyerName)
          .concat(buyerPhone)
          .concat(buyerEmail)
          .concat(recipientName)
          .concat(recipientPhone)
          .concat(depositorName)
          .concat(createdAt)
      },

      set(items) {
        Object.assign(this.searchParams, {
          state: [],
          pay_method: [],
          order_no: [],
          myshop_name: [],
          product_name: [],
          vendor_idx: [],
          vendor_name: [],
          legacy_vendor_no: [],
          tracking_no: [],
          buyer_name: [],
          buyer_phone: [],
          buyer_email: [],
          recipient_name: [],
          recipient_phone: [],
          depositor_name: [],
          created_at: [],
        })
        items.forEach(item => {
          const [key, value] = item.split(':')
          if (key === '주문상태') {
            const state = this.stateOptions.find(o => o.text === value)
            this.searchParams.state.push(state.value)
          } else if (key === '결제수단') {
            const payMethod = this.payMethodOptions.find(o => o.text === value)
            this.searchParams.pay_method.push(payMethod.value)
          } else if (key === '주문번호') {
            this.searchParams.order_no.push(value)
          } else if (key === '스토어명') {
            this.searchParams.myshop_name.push(value)
          } else if (key === '상품명') {
            this.searchParams.product_name.push(value)
          } else if (key === '벤더번호') {
            this.searchParams.vendor_idx.push(value)
          } else if (key === '벤더명') {
            this.searchParams.vendor_name.push(value)
          } else if (key === '벤더번호(1.0)') {
            this.searchParams.legacy_vendor_no.push(value)
          } else if (key === '송장번호') {
            this.searchParams.tracking_no.push(value)
          } else if (key === '구매자_이름') {
            this.searchParams.buyer_name.push(value)
          } else if (key === '구매자_휴대번호') {
            this.searchParams.buyer_phone.push(value)
          } else if (key === '구매자_이메일') {
            this.searchParams.buyer_email.push(value)
          } else if (key === '수령자_이름') {
            this.searchParams.recipient_name.push(value)
          } else if (key === '수령자_휴대번호') {
            this.searchParams.recipient_phone.push(value)
          } else if (key === '입금자_이름') {
            this.searchParams.depositor_name.push(value)
          } else if (key === '주문일시') {
            this.searchParams.created_at.push(value)
          }
        })
      },
    },
  },
  mounted() {
    window.addEventListener('keydown', e => {
      if (e.code === 'Enter' && (e.metaKey || e.ctrlKey)) {
        this.search()
      }
    })
  },
  methods: {
    test() {
      console.log(this.searchParams)
    },
    addTag() {
      const key = this.textCategorySelect?.label
      const value = this.textInput
      if (value != null && value !== '') {
        if (key === '주문번호') {
          this.searchParams.order_no.push(value)
        } else if (key === '스토어명') {
          this.searchParams.myshop_name.push(value)
        } else if (key === '상품명') {
          this.searchParams.product_name.push(value)
        } else if (key === '벤더번호') {
          this.searchParams.vendor_idx.push(value)
        } else if (key === '벤더명') {
          this.searchParams.vendor_name.push(value)
        } else if (key === '벤더번호(1.0)') {
          this.searchParams.legacy_vendor_no.push(value)
        } else if (key === '송장번호') {
          this.searchParams.tracking_no.push(value)
        } else if (key === '구매자 이름') {
          this.searchParams.buyer_name.push(value)
        } else if (key === '구매자 휴대번호') {
          this.searchParams.buyer_phone.push(value)
        } else if (key === '구매자 이메일') {
          this.searchParams.buyer_email.push(value)
        } else if (key === '수령자 이름') {
          this.searchParams.recipient_name.push(value)
        } else if (key === '수령자 휴대번호') {
          this.searchParams.recipient_phone.push(value)
        } else if (key === '입금자 이름') {
          this.searchParams.depositor_name.push(value)
        } else if (key === '주문일시') {
          this.searchParams.created_at.push(value)
        }
      }
      this.textInput = null
    },
    search() {
      this.$emit('search', this.searchParams)
    },
    download() {
      this.$emit('download', this.searchParams)
    },
    reload() {
      this.$emit('variable-sync')
      this.search()
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
